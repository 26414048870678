import { version } from './version';

export const environment = {
  BASE_URL: 'https://dtecaruaru.giss.com.br/PlatformaDTe',
  production: false,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  powerbi: '2604106',
  schema: 'pbi_dte_caruaru',
  groupId: '88800c1f-b2c3-4bb9-b74a-ac33110e1b7e',
  analiseGeral: '4c9737e5-5dc8-4e7d-86ac-e8d8083e66fc',
  analiseUsuarios: '239a8b31-9ede-48f8-b45a-7f547e98f29c',
  analiseComunicados: '7b3408a4-fe97-488a-ad98-ab805264153c',
  analiseProcurador: 'ac008e67-8bd8-4188-b742-9789a549d7b0',
  analiseEventos: '9391b92c-ec6d-4a24-8cf6-4cd48c4ba46e'
};
